import React, { useEffect, useRef, useMemo } from 'react';
import { Grid } from '@mui/material';
import { ErrorPage } from '@components/ErrorPage';
import { Redirect } from '@components/Redirect';
import { PageHelmet } from '@components/PageHelmet';
import { PageSection } from '@components/PageSection';
import { ensureAnchorIsLoaded } from '@helpers/window';
import { useAnimationObserver } from '@hooks/useAnimationObserver';
import { checkIfReduceMotion } from '@helpers/browserHelper';
import { useStaticValues } from '@stateManagement/StaticValuesContext';
import { PublicPage } from '@models/page';
import { MobileDemoBanner } from '@components/MobileDemoBanner/MobileDemoBanner';

const classes = {
    mainContent: {
        transition: 'padding 225ms cubic-bezier(0, 0, 0.2, 1)',
    },
};

function formSeo(page: PublicPage, prefixStringWithBaseUrl: (value?: string) => string): Record<string, unknown> {
    const { title, description, canonical, modifiedDateUtc, prefixedImagePath } = page;
    return prefixedImagePath
        ? {
            title,
            description,
            canonical,
            dateModified: modifiedDateUtc,
            imageUrl: prefixStringWithBaseUrl(prefixedImagePath),
        }
        : { title, description, canonical, dateModified: modifiedDateUtc };
}

export default function PageSlug(): JSX.Element {
    const { page, prefixStringWithBaseUrl } = useStaticValues();
    const reducedMotion = useRef(false);
    const pageChange = useMemo(() => !!page && !reducedMotion.current, [page, reducedMotion]);
    useAnimationObserver(pageChange);
    const disableMobileDemoBanner = page?.mobileDemoBannerDisabled;

    useEffect(() => {
        if (window) {
            reducedMotion.current = checkIfReduceMotion(window);
        }
    }, []);

    useEffect(() => {
        ensureAnchorIsLoaded();
    }, [page]);

    return page ? (
        page.redirectUrl ? (
            <Redirect redirectUrl={page.redirectUrl} statusCode={200} />
        ) : (
            <Grid css={classes.mainContent}>
                <Grid>
                    <PageHelmet seo={formSeo(page, prefixStringWithBaseUrl)} noIndex={page.noIndex} />
                    {!disableMobileDemoBanner ? <MobileDemoBanner /> : null}
                    {Array.isArray(page.sections)
                        ? page.sections.map((section, index) => <PageSection key={index} section={section} />)
                        : null}
                </Grid>
            </Grid>
        )
    ) : (
        <ErrorPage />
    );
}
